* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --dark-blue: #0f0426;
  --medium-state-blue: #7167fe;
  --goldenrod: #e0b03c;
  --fandango: #b12e7b;
  --black: #1e1e1e;
  --davys-grey: #5d5d5d;
  --grey-medium: #cfcfcf;
  --cultured: #f6f7f9;
  --white: #ffffff;
}

body, html {
  font-family: "Montserrat", sans-serif;
  background-color: var(--cultured);
  scroll-behavior: smooth;
}