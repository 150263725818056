h1,
h2 {
  color: var(--dark-blue);
}

h1 {
  font-size: 2rem;
}

h1 span {
  color: var(--white);
}

a {
  text-decoration: none;
}

.btn {
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.primary {
  background-color: var(--white);
  color: var(--medium-state-blue);
  text-transform: uppercase;
}

.secondary {
  color: var(--white);
  border: 2px solid var(--white);
}

.purple {
  color: var(--fandango);
  border: 2px solid var(--fandango);
  text-transform: uppercase;
}

.submit {
  background-color: var(--medium-state-blue);
  border: none;
  color: var(--white);
}

/* Header */

#header {
  height: 85vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

#video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(113, 103, 254, 0.3);
}

.header-content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: #fff; 
    padding: 20px;
}

#header h1 {
  text-align: center;
  margin-bottom: 36px;
  width: 250px;
}

#stats {
  position: absolute;
  bottom: -45px;
  background-color: var(--dark-blue);
  width: 284px;
  height: 86px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#stats ul {
  display: flex;
  flex: 1;
}

#stats ul li {
  list-style-type: none;
  color: var(--cultured);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
}

#stats ul li span {
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-state-blue);
  text-transform: uppercase;
}

#navbar {
  position: absolute;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

#navbar #logo {
  display: flex;
  justify-content: center;
  margin-right: 4px;
}

#navbar #logo span {
  color: var(--white);
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-size: 36px;
  text-transform: uppercase;
}

#menu-toggle {
  cursor: pointer;
}

#mobile-menu {
  display: none;
  position: absolute;
  top: 80px;
  right: 24px;
  width: 200px;
  background-color: var(--cultured);
  border-radius: 10px;
  cursor: pointer;
}

#mobile-menu li {
  list-style-type: none;
  font-size: 12px;
  font-weight: 700;
  padding: 12px;
  border-bottom: 1px solid var(--grey-medium);
}

#mobile-menu li a {
    color: var(--davys-grey);    
}

#mobile-menu li a:hover {
    color: var(--medium-state-blue);    
}

#desktop-menu {
  display: none;
}

@media screen and (min-width: 768px) {
  #navbar {
    padding: 24px 80px;
  }

  #header h1 {
    width: 500px;
    font-size: 2.5rem;
  }

  #stats {
    width: 600px;
    height: 103px;
  }
}

@media screen and (min-width: 1440px) {
  #menu-toggle,
  #mobile-menu {
    display: none;
  }

  #navbar #mobile-menu {
    display: none;
  }

  #navbar {
    padding: 24px 170px;
  }

  #navbar ul {
    display: flex;
  }

  #navbar ul li {
    list-style-type: none;
    font-weight: 700;
    padding: 10px 15px;
    color: var(--white);
    gap: 10px;
  }

  .text-white {
    color: var(--white);    
    }

    #navbar li a:hover {
    color: var(--medium-state-blue);    
    }

  #header h1 {
    width: 664px;
    font-size: 4.2rem;
  }

  #stats {
    bottom: -90px;
    width: 934px;
    height: 193px;
  }

  #stats ul li {
    font-size: 3.5rem;
  }

  #stats ul li span {
    font-size: 1.25rem;
    font-weight: 500;
  }
}

/* SECTIONS */
section {
  padding: 24px;
  background-color: var(--cultured);
}

#about_us,
#your_events,
#we_offer,
#contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

.section-img-container {
  width: 230px;
}

.section-img-container img {
  width: 100%;
}

#about_us-img,
#our_goal-img,
#contact-img{
  border-radius: 10%;
}
.section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section h2 {
  color: var(--dark-blue);
  font-size: 2rem;
  font-weight: 500;
  margin: 24px 0;
}

.subtitle {
  text-transform: uppercase;
  color: var(--medium-state-blue);
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 3px;
  margin-top: 24px;
}

section p {
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 20px;
}

#we_offer {
  background-color: var(--dark-blue);
  position: relative;
}

#we_offer h2 {
  color: var(--white);
}

#we_offer h2 span {
  color: var(--goldenrod);
}

#usa_map {
  width: 264px;
  margin-top: 24px;
}

#usa_map img {
  width: 100%;
}

.offer-items-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.offer-item {
  display: flex;
  align-items: center;
  border: 2px solid var(--white);
  border-radius: 16px;
  padding: 0 24px;
  width: 284px;
  height: 78px;
  margin-bottom: 12px;
}

.offer-item .description {
  color: var(--goldenrod);
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 0;
  margin-left: 12px;
}

/* -------------------- Services ----------------------- */

#services {
  background-color: var(--medium-state-blue);
}

.small-heading {
  text-align: center;
  color: var(--white);
  font-weight: 500;
  letter-spacing: 3px;
  font-size: 1.25rem;
  margin: 44px 0;
}

.card-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  margin-top: 24px;
}

.card-container::-webkit-scrollbar {
  display: none;
}

.card {
  background-color: var(--white);
  width: 252px;
  height: 439px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 12px;
}

.card-img {
  width: 252px;
  overflow: hidden;
}

.card-img img {
  width: 100%;
}

.card h3 {
  color: var(--goldenrod);
  text-transform: uppercase;
  padding: 12px 0;
  font-weight: 800;
}

.card p {
  width: 100%;
}

.card-body {
  padding: 12px;
}

/* CONTACT FORM */
#contact h2 {
  color: var(--goldenrod);
  font-size: 1.65rem;
  font-weight: 700;
}

#contact .subtitle-2 {
  margin-top: 20px;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
}

#contact .subtitle-2 span {
  color: var(--dark-blue);
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-group {
  margin-bottom: 12px;
}

.contact-form input,
select,
select option,
textarea {
  border: none;
  outline: var(--fandango);
  padding: 16px;
  border-radius: 4px;
  width: 100%;
}

.contact-form input::placeholder,
select::placeholder,
textarea::placeholder,
select {
  color: var(--davys-grey);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

@media screen and (min-width: 768px) {
  #about_us {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  #your_events,
  #contact {
    flex-direction: row;
    justify-content: space-between;
  }

  #your_events .section-content {
    align-items: end;
    width: 527px;
    text-align: right;
  }

  .section-content {
    align-items: start;
    width: 527px;
  }

  section h2 {
    color: var(--dark-blue);
    font-size: 2rem;
    font-weight: 700;
    margin: 24px 0;
  }

  .section-img-container {
    width: 459px;
    justify-self: end;
  }
}

@media screen and (min-width: 1440px) {
  section {
    padding: 24px 170px;
  }

  #about_us {
    margin-top: 150px;
  }

  section h2 {
    color: var(--dark-blue);
    font-size: 2.5rem;
    font-weight: 700;
    margin: 24px 0;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
  }

  .card {
    height: 400px;
    margin-bottom: 24px;
  }

  .card-img {
    width: auto;
  }

  #contact h2 {
    font-size: 3rem;
    letter-spacing: normal;
  }

  #contact .subtitle-2 {
    margin-top: 20px;
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
  }

  .contact-form .input-name {
    border: none;
    outline: var(--fandango);
    padding: 16px;
    border-radius: 4px;
    width: 50%;
  }
}

/* Footer */

footer {
  background-color: var(--dark-blue);
  padding-top: 48px;
  color: var(--white);
}

.footer-item {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-bottom: 32px;
}

.footer-menu li {
  list-style-type: none;
  text-align: center;
  padding-bottom: 24px;
}

.footer-menu li a {
  color: var(--white);
  text-decoration: none;
}

.footer-menu li a:hover {
  color: var(--fandango);
}

.footer-menu-social {
  display: flex;
}

.footer-menu-social li {
  list-style-type: none;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  line-height: 26px;
}

.copyright {
  text-align: center;
  font-size: 10px;
  padding: 24px 0;
}

@media screen and (min-width: 1440px) {
  footer {
    position: relative;
    overflow: hidden;
    padding: 100px 170px 0;
  }

  .footer-items {
    display: flex;
  }

  .footer-item {
    flex: 1;
    margin-bottom: 0;
  }

  .footer-contact p {
    text-align: right;
  }

  .copyright {
    font-size: 12px;
  }

  .footer-logo img {
    position: absolute;
    bottom: -200px;
    width: 363px;
    height: auto;
  }
}

